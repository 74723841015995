import React, { useRef, useState, useEffect, useCallback } from "react"
import { Layer, Image, Transformer } from "react-konva"
import Konva from "konva"
import { URLImage } from "./QRCodeDownLoad"

interface QRLayerProps {
  qrObj: {
    x: number
    y: number
    width: number
    height: number
    textEditVisible: boolean
  }
  width: number
  imageSrc: any
  imageClose: HTMLImageElement
  imageEdit: HTMLImageElement
  show: boolean
  handleCloseQR: (e: any) => void
  handleQRDblClick: () => void
  handleQrResize?: (newWidth: number, newHeight: number) => void
}

const QRLayer: React.FC<QRLayerProps> = ({
  qrObj,
  width,
  imageSrc,
  imageClose,
  imageEdit,
  show,
  handleCloseQR,
  handleQRDblClick,
  handleQrResize,
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const [currentSize, setCurrentSize] = useState({
    width: width > 1279 ? 187 : width > 767 ? 125 : 72,
    height: width > 1279 ? 187 : width > 767 ? 125 : 72,
  })

  const imageRef = useRef<Konva.Image>(null)
  const transformerRef = useRef<Konva.Transformer>(null)
  const closeButtonRef = useRef<Konva.Image>(null)
  const editButtonRef = useRef<Konva.Image>(null)

  useEffect(() => {
    if (isSelected && transformerRef.current && imageRef.current) {
      transformerRef.current.nodes([imageRef.current])
      transformerRef.current.getLayer()?.batchDraw()
    }
  }, [isSelected])

  const handleSelect = useCallback(() => {
    setIsSelected((prev) => !prev)
    handleQRDblClick()
  }, [])

  const handleDeselect = useCallback(
    (e: any) => {
      setIsSelected(false)
      handleCloseQR(e)
    },
    [handleCloseQR],
  )

  const handleTransform = useCallback(() => {
    const node = imageRef.current
    if (!node) return

    const scaleX = node.scaleX()
    const scaleY = node.scaleY()
    const minSize = 50
    const maxSize = 300

    const newWidth = Math.max(minSize, Math.min(maxSize, node.width() * scaleX))
    const newHeight = Math.max(
      minSize,
      Math.min(maxSize, node.height() * scaleY),
    )

    node.width(newWidth)
    node.height(newHeight)
    node.scaleX(1)
    node.scaleY(1)

    setCurrentSize({ width: newWidth, height: newHeight })
    handleQrResize?.(newWidth, newHeight)

    if (closeButtonRef.current) {
      closeButtonRef.current.position({ x: node.x() - 11, y: node.y() - 22 })
    }
    if (editButtonRef.current) {
      editButtonRef.current.position({
        x: node.x() + newWidth - 11,
        y: node.y() + newHeight + 5,
      })
    }

    node.getLayer()?.batchDraw()
  }, [handleQrResize])

  return (
    <Layer
      // onClick={handleDeselect}
      draggable={true}
      opacity={qrObj.textEditVisible && !show ? 0 : 1}
    >
      {/* Close Button */}
      <URLImage
        opacity={qrObj.textEditVisible ? 1 : 0}
        src={imageClose}
        x={qrObj.x - 14}
        y={qrObj.y - 25}
        width={22}
        height={22}
        funcClick={handleDeselect}
      />

      {/* QR Code Image */}
      <Image
        ref={imageRef}
        image={imageSrc}
        x={qrObj.x}
        y={qrObj.y}
        width={currentSize.width}
        height={currentSize.height}
        onDblClick={handleSelect}
        onDblTap={handleSelect}
        // onClick={handleSelect}
        onTransform={handleTransform}
      />

      {/* Edit Button */}
      <Image
        ref={editButtonRef}
        opacity={0}
        image={imageEdit}
        x={qrObj.x + currentSize.width - 11}
        y={qrObj.y + currentSize.height + 5}
        width={22}
        height={22}
      />

      {/* Transformer */}
      {isSelected && (
        <Transformer
          ref={transformerRef}
          rotateEnabled={false}
          borderStrokeWidth={5}
          enabledAnchors={["bottom-right"]}
          boundBoxFunc={(oldBox, newBox) => {
            const MIN_SIZE = 50
            const MAX_SIZE = 300
            return {
              ...newBox,
              width: Math.max(MIN_SIZE, Math.min(MAX_SIZE, newBox.width)),
              height: Math.max(MIN_SIZE, Math.min(MAX_SIZE, newBox.height)),
            }
          }}
        />
      )}
    </Layer>
  )
}

export { QRLayer }
