import React, { useRef, useEffect, useCallback, useState } from "react"
import { Layer, Text, Transformer } from "react-konva"
import Konva from "konva"
import { URLImage } from "@/components/QrDesign/canvas/QRCodeDownLoad"

type EditableLayerProps = {
  countObj: any // Replace `any` with the correct type if available
  width: number
  height: number
  handleKeyDown?: () => void
  handleCounterResize?: (newWidth: number, newHeight: number) => void
  handleTextDblClick: (
    e: any,
    countObj: any,
    setCountObj: React.Dispatch<React.SetStateAction<any>>,
  ) => void
  imageClose: string
  imageEdit: string
  setCountObj: React.Dispatch<React.SetStateAction<any>>
  show?: boolean
}

const CounterLayer: React.FC<EditableLayerProps> = ({
  countObj,
  handleKeyDown,
  handleTextDblClick,
  handleCounterResize,
  imageClose,
  imageEdit,
  setCountObj,
  width,
  height,
  show = false,
}) => {
  const textNodeRef = useRef<any>(null)
  const transformerRef = useRef<any>(null)
  const closeButtonRef = useRef<Konva.Image>(null)
  const editButtonRef = useRef<Konva.Image>(null)

  const [currentSize, setCurrentSize] = useState({
    width: width > 1279 ? 187 : width > 767 ? 125 : 72,
    height: width > 1279 ? 187 : width > 767 ? 125 : 72,
  })

  useEffect(() => {
    if (textNodeRef.current && transformerRef.current) {
      // Attach transformer to the text node
      transformerRef.current.nodes([textNodeRef.current])
      transformerRef.current.getLayer()?.batchDraw()
    }
  }, [countObj]) // Re-run on changes to countObj

  // Handle resizing
  const handleTransform = useCallback(() => {
    const node = textNodeRef.current
    if (!node) return

    const scaleX = node.scaleX()
    const scaleY = node.scaleY()
    const minSize = 50
    const maxSize = 300

    const newWidth = Math.max(minSize, Math.min(maxSize, node.width() * scaleX))
    const newHeight = Math.max(
      minSize,
      Math.min(maxSize, node.height() * scaleY),
    )

    node.width(newWidth)
    node.height(newHeight)
    node.scaleX(1)
    node.scaleY(1)

    setCurrentSize({ width: newWidth, height: newHeight })
    handleCounterResize?.(newWidth, newHeight)

    if (closeButtonRef.current) {
      closeButtonRef.current.position({ x: node.x() - 11, y: node.y() - 22 })
    }
    if (editButtonRef.current) {
      editButtonRef.current.position({
        x: node.x() + newWidth - 11,
        y: node.y() + newHeight + 5,
      })
    }

    node.getLayer()?.batchDraw()
  }, [handleCounterResize])

  return (
    <Layer
      draggable={countObj.textEditVisible}
      opacity={countObj.textEditVisible && !show ? 0 : 1}
    >
      <URLImage
        opacity={countObj.textEditVisible ? 1 : 0}
        src={imageClose}
        x={countObj.x}
        y={countObj.y - 42}
        funcClick={handleKeyDown}
        width={22}
        height={22}
      />
      <Text
        ref={textNodeRef}
        fontFamily={countObj.fontFamily}
        fontStyle={countObj.fontStyle}
        fontSize={countObj.fontSize}
        textDecoration={countObj.textDecoration}
        align={countObj.align}
        y={countObj.y}
        x={countObj.x}
        fill={countObj.fill}
        text={countObj.textValue}
        wrap="word"
        width={countObj.width}
        height={countObj.height}
        onDblTap={(e) => handleTextDblClick(e, countObj, setCountObj)}
        onDblClick={(e) => handleTextDblClick(e, countObj, setCountObj)}
        sceneFunc={(context, shape) => {
          const radius = 4
          context.fillStyle = countObj.color
          context.beginPath()
          context.moveTo(radius, -15)
          context.lineTo(shape.width() - radius, -15)
          context.arcTo(shape.width(), -15, shape.width(), -15 + radius, radius)
          context.lineTo(shape.width(), shape.height() - radius - 15)
          context.arcTo(
            shape.width(),
            shape.height() - 15,
            shape.width() - radius,
            shape.height() - 15,
            radius,
          )
          context.lineTo(radius, shape.height() - 15)
          context.arcTo(
            0,
            shape.height() - 15,
            0,
            shape.height() - radius - 15,
            radius,
          )
          context.lineTo(0, -15 + radius)
          context.arcTo(0, -15, radius, -15, radius)
          context.closePath()
          context.fill()
          ;(shape as Konva.Text)._sceneFunc(context)
        }}
      />
      {/* Transformer for resizing */}
      <Transformer
        ref={transformerRef}
        onTransformEnd={handleTransform}
        rotateEnabled={false}
        enabledAnchors={["middle-right"]}
        boundBoxFunc={(oldBox, newBox) => {
            const MIN_SIZE = 50
            const MAX_SIZE = 300
            return {
                ...newBox,
                width: Math.max(MIN_SIZE, Math.min(MAX_SIZE, newBox.width)),
                height: Math.max(MIN_SIZE, Math.min(MAX_SIZE, newBox.height)),
            }
        }}
      />
    </Layer>
  )
}

export { CounterLayer }
